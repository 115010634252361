<op-basic-range-date-picker
  [id]="'values-' + filter.id"
  [name]="'v[' + filter.id + ']'"
  class="advanced-filters--date-field"

  [value]="value"
  (valueChange)="value = $event"
  [opAutofocus]="shouldFocus"
></op-basic-range-date-picker>


<span
  class="advanced-filters--tooltip-trigger -multiline"
  *ngIf="isTimeZoneDifferent"
  [attr.data-tooltip]="timeZoneText"
>
  <span class="spot-icon spot-icon_warning"></span>
</span>
