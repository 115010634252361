import { Component, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { PathHelperService } from 'core-app/core/path-helper/path-helper.service';
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';
import { OpModalService } from 'core-app/shared/components/modal/modal.service';
import { HalResource } from 'core-app/features/hal/resources/hal-resource';
//import it

import * as XLSX from 'ts-xlsx';
import { HttpClient } from '@angular/common/http';
import { CurrentUserService } from 'core-app/core/current-user/current-user.service';
import { HalResourceService } from 'core-app/features/hal/services/hal-resource.service';


@Component({
  selector: 'op-new-import-project',
  templateUrl: './new-import-project.component.html',
  styleUrls: ['./new-import-project.component.sass'],
})
export class NewImportProjectComponent implements OnInit {

  isAdmin = false;
  oneCheckFailed = false;
  importSuccessfull = false;

  private customParamsStatutProjectId:any;
  private customParamsAvailable:any;
  private resourcePath:string;

  private specificCustomParamsAvailable:any;
  private specificCustomOptionsAvailable:any;

  private initialPayload:{ _links:{ parent:{ href:string } } };
  arrayBuffer:any;
  jsonExcel:any;
  errorWithRow:number;
  keys:any;
  file:File|null;
  private fileReader:FileReader;
  private allMemberships:any;

  constructor(
    private apiV3Service:ApiV3Service,
    private uIRouterGlobals:UIRouterGlobals,
    private httpClient:HttpClient,
    readonly halResourceService:HalResourceService,
    private pathHelperService:PathHelperService,
    private modalService:OpModalService,
  ) {
    this.resourcePath = this.apiV3Service.projects.path;
  }

  async ngOnInit():Promise<any> {


    // TODO: refactor this in a common service

    // NEW API for retrieving existing custom fields for projects
    // projects/projects_custom_fields

    const apiProjectMembershipsEndpoint = location.origin + '/api/v3/memberships'
    this.allMemberships = await this.httpClient.get(apiProjectMembershipsEndpoint).toPromise() as any; // HALResource Project type

    this
      .apiV3Service
      .projects
      .projectCustomParams
      .get()
      .toPromise()
      .then((responseForSpecificProject:HalResource) => {

        this.specificCustomParamsAvailable = responseForSpecificProject.$source || [];

        this
          .apiV3Service
          .projects
          .customParams
          .get()
          .toPromise()
          .then((response:HalResource) => {

            this.customParamsAvailable = response.$source || [];

            if (this.uIRouterGlobals.params.parent_id) {
              this.setParentAsPayload(this.uIRouterGlobals.params.parent_id);
            }

            this
              .apiV3Service
              .projects
              .projectCustomOptions
              .get()
              .toPromise()
              .then((responseForSpecificProjectCustomOptions:HalResource) => {

                this.specificCustomOptionsAvailable = responseForSpecificProjectCustomOptions.$source || [];

                this.halResourceService.get(this.apiV3Service
                  .users
                  .me
                  .path)
                  .toPromise()
                  .then((user:any) => {

                    const userId = user.$source.id;
                    this.isAdmin ||= user.$source.admin;

                  });
              });
          });
      });

  }

  private setParentAsPayload(parentId:string) {
    const href = this.apiV3Service.projects.id(parentId).path;

    this.initialPayload = {
      _links: {
        parent: {
          href,
        },
      },
    };
  }


  raz(event:any) {
    event.target.value = '';
  }

  incomingfile(event:any) {
    this.file = event.target.files[0];
    this.parseJson();
    this.errorWithRow = -1;
    // this.uploadToNewProjectApiDryRun().catch(e => console.log(e));
  }

  clickOnInputFile() {
    this.importSuccessfull = false;
    this.jsonExcel = null;
    this.oneCheckFailed = false;
    this.file = null;
    this.fileReader = new FileReader()
    // @ts-ignore
    document.getElementById('inputFile').click();
  }

  parseJson() {
    this.fileReader = new FileReader();
    this.fileReader.onload = (e) => {
      this.arrayBuffer = this.fileReader.result;
      let data = new Uint8Array(this.arrayBuffer);
      let arr = new Array();
      for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      let bstr = arr.join("");
      let workbook = XLSX.read(bstr, { type: "binary" });
      let first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];
      this.jsonExcel = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.keys = Object.keys(this.jsonExcel[0]);
      console.log(this.jsonExcel);
    }
    this.fileReader.readAsArrayBuffer(this.file as File);
  }

  async uploadToNewProjectApi() {

    let listOfPayloads = [];
    this.oneCheckFailed = false;

    const apiEndpoint = location.origin + '/api/v3/projects?pageSize=2000'
    // "/api/v3/projects/146"
    const allProjects = await this.httpClient.get(apiEndpoint).toPromise() as any; // HALResource Project type

    for (const [rowNb, newProjectNonCasted] of Object.entries(this.jsonExcel)) {

      this.errorWithRow = -1;
      const rowNbIndexed = (Number.parseFloat(rowNb) + 1);
      const newProject = newProjectNonCasted as any;
      let found = allProjects._embedded.elements.find((u:any) => u.identifier === newProject['Identifiant du parent']);

      if (newProject['Identifiant du parent'] === '' || newProject['Identifiant du parent'] === undefined) {
        found = { active: true, newProject: true };
      }

      if (!found || !found.active) {
        this.errorWithRow = rowNbIndexed;
        alert('Ligne ' + rowNbIndexed + ': vous n\'avez pas accès au projet parent ' + newProject['Identifiant du parent'] + ' pour le projet ' + newProject['Nom du projet'] + ', ou ce dernier n\'existe pas');
        this.oneCheckFailed = true;
        return;
      }

      if (!found.newProject && !this.isAdmin) {
        let foundMembership = this.allMemberships._embedded.elements.find((u:any) => u._links.project.href === '/api/v3/projects/' + found.id);

        if (!foundMembership) {
          this.errorWithRow = rowNbIndexed;
          alert('Ligne ' + rowNbIndexed + ': vous n\'avez pas accès au projet parent ' + newProject['Identifiant du parent'] + ' pour le projet ' + newProject['Nom du projet'] + ', ou ce dernier n\'existe pas');
          this.oneCheckFailed = true;
          return;
        }

        const roleTitles = foundMembership._links.roles.map((u:any) => u.title);
        if (roleTitles.indexOf("Project admin") === -1) {
          this.errorWithRow = rowNbIndexed;
          alert('Ligne ' + rowNbIndexed + ': vous n\'avez pas accès au projet parent ' + newProject['Identifiant du parent'] + ' pour le projet ' + newProject['Nom du projet'] + ', ou ce dernier n\'existe pas');
          this.oneCheckFailed = true;
          return;
        }
      }

      let payload = {
        "name": newProject['Nom du projet'],
        "description": { "raw": "" },
        "public": found.public || false,
      } as any;

      if (!found.newProject) {
        const linkToParentId = '/api/v3/projects/' + found.id;
        payload["_links"] =
          {
            "parent": { "href": linkToParentId },
          };
      }
      const keys = Object.keys(newProject);

      if (keys.indexOf("Nom du projet") === -1 || keys.indexOf("Statut projet") === -1) {
        this.errorWithRow = rowNbIndexed;
        alert('Ligne ' + rowNbIndexed + ": il faut remplir les deux champs 'Nom du projet' et 'Statut projet' pour pouvoir importer un projet");
        this.oneCheckFailed = true;
        return;
      }

      for (const [key, value] of Object.entries(newProject)) {
        const found = this.customParamsAvailable.find((u:string[]) => u[15] === key);
        // Chef de projet/Pilote: 'Ruslan Nazaev', Statut projet: 'Non démarré', Identifiant du parent:
        if (found && key !== 'Nom du projet') {

          if (key === 'Statut projet') {
            const valueSt = value as string;
            if ((this.specificCustomOptionsAvailable.map((u:any) => u[4])).indexOf(valueSt) === -1) {
              this.errorWithRow = rowNbIndexed;
              alert('La valeur du Statut n\'est pas correcte: ' + valueSt);
              this.oneCheckFailed = true;
              return;
            } else {
              for (let param of this.customParamsAvailable) {
                if (param[15] === 'Statut projet' && param[2] === 'list') {
                  this.customParamsStatutProjectId = param[0];
                  for (let customOption of this.specificCustomOptionsAvailable) {
                    let hrefResponse = { href: "/api/v3/custom_options/" };
                    if (customOption[4] === valueSt) {
                      hrefResponse.href += '' + customOption[0];
                      // @ts-ignore
                      payload['customField' + this.customParamsStatutProjectId] = hrefResponse;
                      if (listOfPayloads.map(u => u.name).indexOf(payload.name) === -1) {
                        listOfPayloads.push(payload);
                      }
                    }
                  }
                }
              }

            }
          } else {
            // @ts-ignore
            payload['customField' + found[0]] = value;
            if (listOfPayloads.map(u => u.name).indexOf(payload.name) === -1) {
              listOfPayloads.push(payload);
            }
          }
        }
      }
      if (this.oneCheckFailed) {
        return;
      }
    }

    try {
      for (let p of listOfPayloads) {
        await this.apiV3Service.projects.halResourceService.post(apiEndpoint, p).toPromise();
      }
      alert('L\'import des projets s\'est déroulé avec succès');
      this.importSuccessfull = true;
    } catch (e) {
      alert(e.message);
    }
    this.file = null;
  }

}
