// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2023 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractWidgetComponent } from 'core-app/shared/components/grids/widgets/abstract-widget.component';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { CurrentProjectService } from 'core-app/core/current-project/current-project.service';
import { SchemaAttributeObject, SchemaResource } from 'core-app/features/hal/resources/schema-resource';
import { Observable } from 'rxjs';
import { ProjectResource } from 'core-app/features/hal/resources/project-resource';
import { HalResourceEditingService } from 'core-app/shared/components/fields/edit/services/hal-resource-editing.service';
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';
import { HalResource } from 'core-app/features/hal/resources/hal-resource';

@Component({
  templateUrl: './project-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    HalResourceEditingService,
  ],
})
export class WidgetProjectDetailsComponent extends AbstractWidgetComponent implements OnInit {
  @ViewChild('contentContainer', { static: true }) readonly contentContainer:ElementRef;

  public customFields:{ key:string, label:string }[] = [];

  public project$:Observable<ProjectResource>;

  public specificCustomParamsAvailable:any[] = [];

  public customParamsAvailable:any[] = [];

  constructor(protected readonly i18n:I18nService,
    protected readonly injector:Injector,
    protected readonly apiV3Service:ApiV3Service,
    protected readonly currentProject:CurrentProjectService,
    protected readonly cdRef:ChangeDetectorRef) {
    super(i18n, injector);
  }

  ngOnInit() {

    // TODO: refactor this in a common service

    // NEW API for retrieving existing custom fields for projects
    // projects/projects_custom_fields

    this
      .apiV3Service
      .projects
      .projectCustomParams
      .get()
      .toPromise()
      .then((responseForSpecificProject:HalResource) => {

        this.specificCustomParamsAvailable = responseForSpecificProject.$source || [];

        const currentProjectId = this.currentProject?.current?.id;

        const paramsFromSpecificForProject = !!currentProjectId ? this.specificCustomParamsAvailable!
          .filter(u =>
            !!u[3] && u[1] == currentProjectId,
          )
          .map(u => {
            return { id: u[3], availableForAll: false }
          }) : [];


        this
          .apiV3Service
          .projects
          .customParams
          .get()
          .toPromise()
          .then((response:HalResource) => {

            this.customParamsAvailable = response.$source || []
            paramsFromSpecificForProject.map(p => {
              let val = this.customParamsAvailable.find(u => u[0] == p.id);
              if (!val) {
                return;
              }
              val[7] = true; // to display in widget we set the param as globally enable for the project (on frontend side only)
              return val;
            });

            this.loadAndRender();
            this.project$ = this
              .apiV3Service
              .projects
              .id(this.currentProject.id!)
              .requireAndStream();

          });
      });
  }

  public get isEditable():boolean {
    return false;
  }

  private loadAndRender():void {
    void Promise.all([
      this.loadProjectSchema(),
    ])
      .then(([schema]) => {
        this.setCustomFields(schema);
      });
  }

  private loadProjectSchema():Promise<SchemaResource> {
    return this
      .apiV3Service
      .projects
      .schema
      .get()
      .toPromise();
  }

  private setCustomFields(schema:SchemaResource) {
    Object.entries(schema).forEach(([key, keySchema]) => {
      if (/customField\d+/.exec(key) && this.customParamsAvailable.length == 0) {
        this.customFields.push({ key, label: keySchema.name });
      }
      if (this.customParamsAvailable.length > 0) {
        const isForAllParams = this.customParamsAvailable
          .map(u => { return { id: u[0], availableForAll: u[7] }; })
          .filter(u => u.availableForAll);
        for (const availableP of isForAllParams) {
          if (new RegExp('customField' + availableP.id + '$').exec(key)) {
            this.customFields.push({ key, label: keySchema.name });
            continue;
          }
        }
      }
    });

    this.cdRef.detectChanges();
  }
}
