// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2023 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { PathHelperService } from 'core-app/core/path-helper/path-helper.service';
import { WorkPackageResource } from 'core-app/features/hal/resources/work-package-resource';
import { UrlParamsHelperService } from 'core-app/features/work-packages/components/wp-query/url-params-helper';
import { WorkPackageRelationsHierarchyService } from 'core-app/features/work-packages/components/wp-relations/wp-relations-hierarchy/wp-relations-hierarchy.service';
import { OpUnlinkTableAction } from 'core-app/features/work-packages/components/wp-table/table-actions/actions/unlink-table-action';
import { OpTableActionFactory } from 'core-app/features/work-packages/components/wp-table/table-actions/table-action';
import { WorkPackageInlineCreateService } from 'core-app/features/work-packages/components/wp-inline-create/wp-inline-create.service';
import { WorkPackageRelationQueryBase } from 'core-app/features/work-packages/components/wp-relations/embedded/wp-relation-query.base';
import { WpChildrenInlineCreateService } from 'core-app/features/work-packages/components/wp-relations/embedded/children/wp-children-inline-create.service';
import { filter } from 'rxjs/operators';
import { QueryResource } from 'core-app/features/hal/resources/query-resource';
import { HalEventsService } from 'core-app/features/hal/services/hal-events.service';
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';
import { GroupDescriptor } from 'core-app/features/work-packages/components/wp-single-view/wp-single-view.component';
import idFromLink from 'core-app/features/hal/helpers/id-from-link';
import { HalResource } from 'core-app/features/hal/resources/hal-resource';
import { HalResourceService } from 'core-app/features/hal/services/hal-resource.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'wp-children-query',
  templateUrl: '../wp-relation-query.html',
  providers: [
    { provide: WorkPackageInlineCreateService, useClass: WpChildrenInlineCreateService },
  ],
})
export class WorkPackageChildrenQueryComponent extends WorkPackageRelationQueryBase implements OnInit {
  @Input() public workPackage:WorkPackageResource;

  @Input() public query:QueryResource;

  /** An optional group descriptor if we're rendering on the single view */
  @Input() public group?:GroupDescriptor;

  @Input() public addExistingChildEnabled = false;

  public idFromLink = idFromLink;

  public modifyInBaseWpPath:string;

  public tableActions:OpTableActionFactory[] = [
    OpUnlinkTableAction.factoryFor(
      'remove-child-action',
      this.I18n.t('js.relation_buttons.remove_child'),
      (child:WorkPackageResource) => {
        if (this.embeddedTable) {
          this.embeddedTable.loadingIndicator = this.wpRelationsHierarchyService.removeChild(child);

          const parent = this.wpInlineCreate.referenceTarget;
          if (!parent) {
            return;
          }

          const customizedId = parent?.id;

          console.log('reducing all children values and calling API endpoint to set value for parent WorkPackage Custom field value');
          console.log(`Api call ${this.modifyInBaseWpPath.replace('idToReplace', parent.id!)}`);

          const customFieldIds = Object.keys(this.wpInlineCreate.referenceTarget!)
            .filter(u => u.indexOf('customField') !== -1 && typeof (this?.wpInlineCreate?.referenceTarget![u]) === 'number')
            .map(u => u.replace('customField', ''));

          setTimeout(async () => {

              for (let customFieldId of customFieldIds) {
                let parentChildren = parent.$links.children
                const childIdToFilter = child.id;

                // @ts-ignore
                const arrayOfChildrenLinks = parentChildren.map((u:{ href:string, $load?:() => Promise<any>; }) => {
                  if (!!u.$load) { return u.$load()} else {
                    return this.httpClient.get(u.href, {
                      withCredentials: true,
                      responseType: 'json',
                    }).toPromise()
                  }
                });
                let res = await Promise.all(arrayOfChildrenLinks);
                res = res.filter(u => u.id != childIdToFilter);
                const valToWriteInDb = res.reduce((a:any, b:any) => {
                    const valB = b['customField' + customFieldId] === null ? 0 : b['customField' + customFieldId];
                    const valToAdd = (!valB || typeof (valB) !== 'number') ? 0 : valB;
                    return a + valToAdd;
                  },
                  0);


                console.log(`Api Params customizedId: ${customizedId} , customFieldId: ${customFieldId}, value: ${valToWriteInDb}`);
                console.log(`Parent id is : ${parent?.id}`);

                await this.modifyWp(
                  parent,
                  customizedId + '',
                  customFieldId + '',
                  valToWriteInDb + '',
                );
                console.log('modifyWp called successfully');

              }

              location.reload()
            },
            1000,
          );
        }
      },
      (child:WorkPackageResource) => !!child.changeParent,
    ),
  ];

  constructor(protected wpRelationsHierarchyService:WorkPackageRelationsHierarchyService,
    protected PathHelper:PathHelperService,
    protected httpClient:HttpClient,
    protected wpInlineCreate:WorkPackageInlineCreateService,
    protected halEvents:HalEventsService,
    protected apiV3Service:ApiV3Service,
    protected queryUrlParamsHelper:UrlParamsHelperService,
    readonly halResourceService:HalResourceService,
    readonly I18n:I18nService) {
    super(queryUrlParamsHelper);
  }

  ngOnInit() {

    this.modifyInBaseWpPath = this.apiV3Service.work_packages.id({ id: 'idToReplace' })?.modify.path;

    // Set reference target and reference class
    this.wpInlineCreate.referenceTarget = this.workPackage;

    // Set up the query props
    this.queryProps = this.buildQueryProps();

    // Fire event that children were added
    this.wpInlineCreate.newInlineWorkPackageCreated
      .pipe(
        this.untilDestroyed(),
      )
      .subscribe((toId:string) => {
        this.halEvents.push(this.workPackage, {
          eventType: 'association',
          relatedWorkPackage: toId,
          relationType: 'child',
        });
      });

    // Refresh table when work package is refreshed
    this
      .apiV3Service
      .work_packages
      .id(this.workPackage)
      .observe()
      .pipe(
        filter(() => !!this.embeddedTable?.isInitialized),
        this.untilDestroyed(),
      )
      .subscribe(() => this.refreshTable());
  }

  private modifyWp(parent:any, customizedId:string, customFieldId:string, value:string):Promise<HalResource> {
    const newLockVersion = parent?.lockVersion + 1;
    console.log(`newLockVersion for parent (root): ${newLockVersion}`);

    const params = {
      customized_id: customizedId + '',
      custom_field_id: customFieldId + '',
      value: value + '',
      parent_wp_id: parent.id + '',
      new_lock_version: (newLockVersion === null ? 0 : newLockVersion),
    };
    return new Promise((res) => this.halResourceService
      .post<any>(this.modifyInBaseWpPath.replace('idToReplace', parent.id), params)
      .subscribe((value) => {
        res(value);
      }),
    );
  }
}
