// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2023 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import {
  Component, Input, EventEmitter, Output,
} from '@angular/core';
import { WorkPackageResource } from 'core-app/features/hal/resources/work-package-resource';
import { WorkPackageRelationsHierarchyService } from 'core-app/features/work-packages/components/wp-relations/wp-relations-hierarchy/wp-relations-hierarchy.service';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import {
  WorkPackageNotificationService,
} from 'core-app/features/work-packages/services/notifications/work-package-notification.service';
import { HalResource } from 'core-app/features/hal/resources/hal-resource';
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';
import { HalResourceService } from 'core-app/features/hal/services/hal-resource.service';
import { HttpClient } from '@angular/common/http';

@Component({
  templateUrl: './wp-breadcrumb-parent.html',
  selector: 'wp-breadcrumb-parent',
})
export class WorkPackageBreadcrumbParentComponent {
  @Input('workPackage') workPackage:WorkPackageResource;

  @Output('onSwitch') onSwitch = new EventEmitter<boolean>();

  public isSaving = false;

  public modifyInBaseWpPath:string;

  public text = {
    edit_parent: this.I18n.t('js.relation_buttons.change_parent'),
    set_or_remove_parent: this.I18n.t('js.relations_autocomplete.parent_placeholder'),
    remove_parent: this.I18n.t('js.relation_buttons.remove_parent'),
    set_parent: this.I18n.t('js.relation_buttons.set_parent'),
  };

  private editing:boolean;

  public constructor(
    protected httpClient:HttpClient,
    protected readonly I18n:I18nService,
    protected apiV3Service:ApiV3Service,
    protected readonly wpRelationsHierarchy:WorkPackageRelationsHierarchyService,
    protected readonly notificationService:WorkPackageNotificationService,
    readonly halResourceService:HalResourceService,
  ) {
  }

  ngOnInit() {
    this.modifyInBaseWpPath = this.apiV3Service.work_packages.id({ id: 'BaseIdToReplace' })?.modify.path;
  }

  public canModifyParent():boolean {
    return !!this.workPackage.changeParent;
  }

  public get parent() {
    return this.workPackage && this.workPackage.parent;
  }

  public get active():boolean {
    return this.editing;
  }

  public close():void {
    this.toggle(false);
  }

  public open():void {
    this.toggle(true);
  }

  public updateParent(newParent:WorkPackageResource|null) {
    this.close();
    const oldParentId = this.workPackage?.parent?.id;
    // @ts-ignore
    let oldParent = !!this.workPackage?.$links?.parent?.$link.href ? this.workPackage?.$links?.parent : {
      empty: true,
      children: [],
    };
    const newParentId = newParent ? newParent.id : null;
    if (_.get(this.parent, 'id', null) === newParentId) {
      return;
    }

    this.isSaving = true;
    this.wpRelationsHierarchy.changeParent(this.workPackage, newParentId)
      .catch((error:any) => {
        this.notificationService.handleRawError(error, this.workPackage);
      })
      .then(() => {

        this.isSaving = false;

        if (oldParentId == newParentId || (!newParentId && !oldParentId)) {
          return;
        }

        if (this.isACustomField() &&
          this.isANumericValue()) {

          console.log('reducing all children values and calling API endpoint to set value for parent WorkPackage Custom field value');
          console.log(`Api call ${this.modifyInBaseWpPath}`);

          if (!this.workPackage?.$links) {
            return;
          }

          // Loop on every custom field that has typeof === 'number'
          const keys = [...new Set(Object.keys(this.workPackage.$source)
            .filter((u:string) => {
                return u.indexOf('customField') !== -1 && typeof (this.workPackage.$source[u]) === 'number'
              },
            ))];

          for (let cfKey of keys) {

            console.log(`cfKey ${cfKey}`);

            (async () => {

              const cfsArray = await this.httpClient.get(
                '/api/v3/work_packages/' + this.workPackage.id + '/all_custom_fields',
                {
                  withCredentials: true,
                  responseType: 'json',
                },
              ).toPromise() as any[];

              const customFieldId = cfKey.replace('customField', '');
              const fieldName = cfsArray.find(u => u[0] === parseInt(customFieldId))[15]; // column 15 = name

              const links = this.workPackage?.$links;
              const parent = await links?.parent;

              if (!links || !parent) {
                return;
              }

              // @ts-ignore
              // oldParents has already been referenced above
              const newParent = !!parent?.$link.href ? await this.httpClient.get(parent.$link.href, {
                withCredentials: true,
                responseType: 'json',
              }).toPromise() as WorkPackageResource : { empty: true, $links: { children: [] }, $source: { id: null } };

              // @ts-ignore
              oldParent = !oldParent.children && !!oldParent?.$link?.href ? await this.httpClient.get(oldParent.$link.href, {
                withCredentials: true,
                responseType: 'json',
              }).toPromise() as WorkPackageResource : { empty: true, $links: { children: [] }, $source: { id: null } };

              const parentForCompute = !newParent.empty ? newParent : oldParent;
              // console.log(`parentForCompute ${JSON.stringify(parentForCompute)}`);
              // @ts-ignore
              const myChildren = parentForCompute?._links?.children || parentForCompute.children;
              console.log(` myChildren ${myChildren}`);

              // @ts-ignore
              const customizedId = parentForCompute?.id;

              // @ts-ignore
              console.log(`Parent id is : ${customizedId}`);

              if (!customizedId) {
                return;
              }

              let valToWriteInDb = 0;

              if (!myChildren) { // nothing to computer
                console.log(`Api Params customizedId: ${customizedId} , customFieldId: ${customFieldId}, value: ${valToWriteInDb}`);
                this.modifyWp(
                  !oldParent.empty ? oldParent : newParent,
                  customizedId + '',
                  customFieldId + '',
                  valToWriteInDb + '',
                ).then(async () => {
                  console.log('modifyWp called successfully');
                }).catch(e => console.error(e));
                return;
              }

              const arrayOfChildrenLinks = myChildren.map((u:{ href:string, $load?:() => Promise<any>; }) => {
                if (!!u.$load) { return u.$load()} else {
                  return this.httpClient.get(u.href, {
                    withCredentials: true,
                    responseType: 'json',
                  }).toPromise()
                }
              });
              const res = await Promise.all(arrayOfChildrenLinks);
              console.log(`children used to compute total ${res}`)
              valToWriteInDb = res.reduce((a:WorkPackageResource, b:WorkPackageResource) => {
                  const valB = b[cfKey] === null ? 0 : b[cfKey];
                  return a + valB;
                },
                0) as number;

              console.log(`Api Params customizedId: ${customizedId} , customFieldId: ${customFieldId}, value: ${valToWriteInDb}`);
              this.modifyWp(
                !oldParent.empty ? oldParent : newParent,
                customizedId + '',
                customFieldId + '',
                valToWriteInDb + '',
              ).then(async () => {
                console.log('modifyWp called successfully');
              }).catch(e => console.error(e));


            })();

          }
        }

      }); // Behaves as .finally()
  }

  private toggle(state:boolean) {
    if (this.editing !== state) {
      this.editing = state;
      this.onSwitch.emit(this.editing);
    }
  }

  private isACustomField():boolean {
    return !!Object.keys(this.workPackage.$source).join(' ').match('customField');
  }

  private isAChild():boolean {
    return !!this.workPackage.$source._links.parent &&
      !!this.workPackage.$source._links.parent.href &&
      !!this.workPackage.$source._links.parent.title;
  }

  private isANumericValue():boolean {
    const values = Object.keys(this.workPackage.$source).map(u => {
      if (u.indexOf('customField') !== -1) {
        return this.workPackage.$source[u];
      }
    });
    const res = values.reduce((a, b) => {return (a || typeof (b) == 'number')}, false);
    return res;
  }

  private getFieldId(fieldName:string):number {
    return parseInt(fieldName.replace('customField', ''));
  }

  private modifyWp(wp:any, customizedId:string, customFieldId:string, value:string):Promise<HalResource|Error> {

    if (!wp) {
      return new Promise(rej => rej(new Error('WP parent undefined')));
    }

    const newLockVersion = wp?.lockVersion + 1;
    console.log(`newLockVersion for parent (root): ${newLockVersion}`);
    const parent_wp_id = wp?.id;
    console.log(`parent id (root): ${parent_wp_id}`);

    const params = {
      customized_id: customizedId,
      custom_field_id: customFieldId,
      value: value,
      parent_wp_id: parent_wp_id,
      new_lock_version: (newLockVersion===null?0:newLockVersion),
    };
    return new Promise((res) => this.halResourceService
      .post<any>(this.modifyInBaseWpPath.replace('BaseIdToReplace', parent_wp_id), params)
      .subscribe((value) => {
        res(value);
      }),
    );
  }

}
