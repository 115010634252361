import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { IOPFormlyFieldSettings } from 'core-app/shared/components/dynamic-forms/typings';
import { UntilDestroyedMixin } from 'core-app/shared/helpers/angular/until-destroyed.mixin';
import { CurrentProjectService } from 'core-app/core/current-project/current-project.service';
import { PathHelperService } from 'core-app/core/path-helper/path-helper.service';
import { HalResource } from 'core-app/features/hal/resources/hal-resource';
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent extends UntilDestroyedMixin implements OnInit {

  public isComponentAvailable = false;

  public customParamsAvailable:any[] = [];

  public specificCustomParamsAvailable:any[] = [];

  projectsPath:string;

  formMethod = 'patch';

  text:{ [key:string]:string };

  dynamicFieldsSettingsPipe:(dynamicFieldsSettings:IOPFormlyFieldSettings[]) => IOPFormlyFieldSettings[];

  hiddenFields = ['identifier', 'active'];

  constructor(
    private _pathHelperService:PathHelperService,
    private _$state:StateService,
    private _currentProjectService:CurrentProjectService,
    private readonly apiV3Service:ApiV3Service,
  ) {
    super();
  }

  ngOnInit():void {
    this.projectsPath = this._currentProjectService.apiv3Path!;
    this.dynamicFieldsSettingsPipe = (dynamicFieldsSettings) => dynamicFieldsSettings
      .reduce((formattedDynamicFieldsSettings:IOPFormlyFieldSettings[], dynamicFormField) => {
        if (this.isFieldHidden(dynamicFormField.key)) {
          dynamicFormField = {
            ...dynamicFormField,
            hide: true,
          };
        }

        return [...formattedDynamicFieldsSettings, dynamicFormField];
      }, []);

    // TODO: refactor this in a common service

    // NEW API for retrieving existing custom fields for projects
    // projects/projects_custom_fields
    this
      .apiV3Service
      .projects
      .projectCustomParams
      .get()
      .toPromise()
      .then((responseForSpecificProject:HalResource) => {

        this.specificCustomParamsAvailable = responseForSpecificProject.$source || [];

        this
          .apiV3Service
          .projects
          .customParams
          .get()
          .toPromise()
          .then((response:HalResource) => {


            this.customParamsAvailable = response.$source || [];
            this.isComponentAvailable = true;
          });
      });
  }

  private isFieldHidden(name:string|undefined) {
    return this.hiddenFields.includes(name || '');
  }
}
